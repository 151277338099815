import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SEO from '../components/seo';
import LocalizedLink from '../components/localizedLink';
import useTranslations from '../components/useTranslations';

const useStyles = makeStyles((theme) => ({

  goBack: { transition: 'transform .3s ease-out' },
  goBackWrapper: {
    /* backgroundColor: 'red', */
    '&:hover $goBack': {
      color: 'green',
      right: '24px',
      transform: 'translate(10px, 0)',
    },
  },

}));

const NotFoundPage = ({ props }) => {
  const classes = useStyles();
  return (
    <>
      <SEO title="404: Not found" />
      <h1>404: الصفحة غير موجودة</h1>
      <p>الصفحة التي تطلبونها غير موجودة، نحن نعمل على حل هذا المشكل</p>
      <LocalizedLink to="/">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ marginLeft: 50 }}
          className={classes.goBackWrapper}
        >
          <Grid item>
            <ArrowForwardIcon fontSize="large" className={classes.goBack} />
          </Grid>
          <Grid item>{useTranslations().backToHome}</Grid>
        </Grid>
      </LocalizedLink>
    </>
  );
};

export default NotFoundPage;
